@font-face {
    font-family: 'SMW';
    src: url('../assets/fonts/smw.woff2') format('woff2'), url('../assets/fonts/smw.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


.page-about {
    .header-bg{
        opacity: 0;
        visibility: hidden;
    }
}
#about {

    &:before {
        content: '';
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 40%;
        height: 100%;
        background-image: linear-gradient(to left, var(--bg), rgba(var(--bg-rgb), 0) 90%),
        linear-gradient(to bottom, var(--bg), rgba(var(--bg-rgb), 0) 60%),
        url(../assets/miriş2.jpg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .about-contact {
        display: flex;
        align-items: center;
        list-style: none;
        margin: 2rem 0;

        li {
            display: flex;
            align-items: center;
            flex: 0 0 auto;
            list-style: none;
            margin: 0 1rem 0 0;
            color: var(--gray);
        }
        a {
            flex: 0 0 auto;
            display: flex;
            align-items: center;
            text-decoration: none;

            &.bt {
                background: var(--purple);
                color: var(--bg);
                padding: 0 1rem;
                height: 2rem;
                border-radius: 3rem;
                margin-left: 1rem;
                line-height: 2rem;

                .ico {
                    margin-left: .5rem;
                    height: 14px;
                    width: 14px;
                }

                &:hover {
                    background: var(--light)
                }
            }

            .ico {
                flex: 0 0 auto;
                overflow: visible;
                width: 1.5rem;
                height: 1.5rem;
                stroke: var(--bg);
                fill: var(--purple);
                stroke-width: 2px;
                transition: all 400ms ease-out;
            }

            &:hover {
                .ico {
                    fill: var(--light)
                }
            }
        }
    }

    .about-grid {
        padding: 3rem 2rem 0 0;
        margin-right: -25vw;
        width: 75vw;

        h2 {
            font-size: 1.2rem;
            margin-bottom: 1rem;
        }

        .columns {
            margin-bottom: 2rem;
        }

        ul {
            display: grid;
            grid-template: 1fr / repeat(4,1fr);
            grid-gap: 2rem;
            grid-row-gap: 1rem;
        }

        li {
            list-style: none;
            margin: 0;
            font-size: 1rem;
            line-height: 1.5em;
            color: var(--gray);
        }

        .tools {
            display: grid;
            grid-template: 1fr / repeat(4,1fr);
            grid-gap: 2rem;
            grid-row-gap: 1rem;

            ul {
                display: block
            }
        }
    }

}
@media screen and (max-width: 1024px) {

    #about {

        &:before {
            position: absolute;
            width: 100%;
            height: 60vh;
            background-image: linear-gradient(to left, var(--bg), rgba(var(--bg-rgb), 0) 80%),
            linear-gradient(to top, var(--bg), rgba(var(--bg-rgb), 0) 60%),
            url(../assets/miriş2.jpg);
        }

        .about-grid {
            padding: 3rem 1rem 0 0;
            margin: 0;
            width: calc(100vw - 4rem);
        }

    }

}
@media screen and (max-width: 768px) {

    #about {

        .about-grid {
            ul {
                grid-template: 1fr / repeat(3,1fr);
            }

            .tools {
                grid-template: 1fr / repeat(3,1fr);

                ul {
                    display: block
                }
            }
        }

    }

}
@media screen and (max-width: 568px) {

    #about {

        &:before {
            height: 80vh;
            background-image: linear-gradient(to left, var(--bg), rgba(var(--bg-rgb), 0) 50%),
            linear-gradient(to top, var(--bg), rgba(var(--bg-rgb), 0) 60%),
            url(../assets/miriş2.jpg);
        }

        .static-container {
            padding-top: 65vh
        }

        .about-grid {
            ul {
                grid-template: 1fr / 1fr;
                grid-gap: 1rem;
            }

            .tools {
                grid-template: 1fr / 1fr;
                grid-gap: 1rem;

                ul {
                    display: block
                }
            }
        }

        .about-contact {
            flex-flow: row wrap;

            li:last-child {
                flex: 0 0 100%;
                margin: 1rem 0 0;
            }
        }

    }

}