// NProgress custom CSS
#nprogress {
    pointer-events: none;
    .bar {
        background: var(--purple);
        position: fixed;
        z-index: 1031;
        top: 0;
        left: 0;
        width: 100%;
        height: 2px;
    }
    .peg {
        display: block;
        position: absolute;
        right: 0px;
        width: 100px;
        height: 100%;
        opacity: 1.0;
        transform: rotate(3deg) translate(0px, -4px);
    }
}

.nprogress-custom-parent {
    overflow: hidden;
    position: relative;
    #nprogress {
        .spinner, .bar {
            position: absolute;
        }
    }
}