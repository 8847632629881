 //Layout
#header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 8rem;
    padding: 0 1rem 5rem;
}

 //Layout container
.header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 3rem;
    max-width: 80rem;
    margin: 0 auto;
}

//Breadcrumb
.header-breadcrumb {
    font-size: 1.2rem;
    font-weight: 700;
    color: var(--purple)
}

//Navigation Menu
.header-nav-button {
    flex: 0 0 auto;
    width: 3rem;
    height: 3rem;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: space-between;
    padding: .8rem;
    margin-right: -1rem; // align with viewport
    background: none;
    border: 0;

    .label { display: none }
    .dots {
        flex: 0 0 auto;
        position: relative;
        width: .25rem;
        height: .25rem;
        border-radius: .25rem;
        background: var(--header-link);
        transition: all 400ms cubic-bezier(0.19, 1, 0.22, 1);
    }
}
.header-nav {
    display: flex;
    align-items: center;

    ul{
        display: flex;
        align-items: center;
        list-style: none;
    }

    li{
        flex: 0 0 auto;
        margin-left: 1rem;
        list-style: none;
    }

    //links
    a {
        height: 2rem;
        display: flex;
        align-items: end;
        color: #dbdbdb;
        transition: color 400ms ease-out;
        line-height: 1;

        &:hover {
            color: var(--header-link-hover);

            svg {
                fill: var(--header-link-hover-icon);
            }
        }

        &.router-link-exact-active {
            opacity: .5;
            cursor: default;
        }

        //icons
        svg {
            display: block;
            width: 1.2rem;
            height: 1.2rem;
            fill: var(--purple);
            position: relative;
            top: 2px;
            transition: fill 400ms ease-out;
        }
    }

}

 @media screen and (max-width: 768px) {

     .header-nav-button { transition: all 1s linear }
     .header-nav-close-button {
         position: absolute;
         top: 0;
         right: 0;
         width: 3rem;
         height: 3rem;
         display: flex;
         align-items: center;
         justify-content: center;
         padding: 0;
         margin: 0;
         background: none;
         border: 0;
         color: var(--purple);
         font-size: 2rem;

         &:hover { color: var(--light); }
     }
     
  

 }

 @media screen and (max-width: 568px) {

    
 }