@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    scroll-behavior: smooth;
}

body {
    overflow-x: hidden;
    max-width: 100vw;
}

.static-container {
    max-width: 100vw;
}

.inline-block {
    display: inline-block;
}

.center {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slick-slide img {
    object-fit: cover;
    image-rendering: auto;
    image-rendering: crisp-edges;
    image-rendering: pixelated;
}

.IconsPad {
    display: flex;
    justify-content: start;
    align-items: center;
}

.IconsPad > .icons {
    font-size: 30px;

}

.row {
    display: flex;
    align-items: center;
}

.greenPad {
    min-width: 30px;
    width: 100px;
    height: 30px;
    background-color: rgba(131, 100, 131, 0.05);
    color: rgb(120, 19, 179);
    padding: 6px;
    border-radius: 3px;
}

.m1 {
    margin: 1rem;
}

.buttonApp {
    margin-top: 30px;
    margin-left: 20px;
    width: 170px;
    height: 50px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    background-color: #682AE9;
    color: white;
    z-index: 33;
}

.relative {
    position: relative;
}

.projectCard {
    height: 260px;
    position: relative;
    border-radius: 7%;
    background-color: #682AE9;
    margin: 25px 5px;

}

.mockupInside {
    position: absolute;
    top: 13px;
    left: 93px;
    width: 125px;
    z-index: 1;
    height: 270px;

}

.browser {
    width: 100%;
    height: 100%;
    border-radius: 7%;
}

.projectCard > .mockup {
    height: 100%;
    position: absolute;
    z-index: 10;
    bottom: 1px;
}

.white {
    color: white;
}

.purple {
    color: #682AE9;
}

.span {
    transition: all 2s;
}

.p-icons-play {
    position: relative;
    top: 13px;
    left: 6px;
}

.p-icons-pouse {
    position: relative;
    top: 13px;

}

.pl1 {
    padding-left: 6%;
    padding-right: 2%;
}

.margin1 {
    margin: 1rem;
}

.mtb1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.link {
    color: white;
    padding-top: 30px;
}

.hoverMe :hover {
    cursor: pointer;
}

.zin {
    z-index: 100;
}

.relative {
    position: relative;
}